import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {
    if ($('div.portfolioCarousel').length) {
        $('.portfolioCarousel').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            autoplay: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: { slidesToShow: 2 }
                },
                {
                    breakpoint: 576,
                    settings: { slidesToShow: 1.5 }
                }
            ]
        });
    }

    if ($('div.aboutTeamCarousel').length) {
        $('.aboutTeamCarousel').slick({
            slidesToShow: 1.6,
            centerMode: true,
            centerPadding: '0px',
            infinite: true,
            autoplay: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '20%',
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '40px'
                    }
                }
            ]
        });
    }


    if ($('.modal').length) {
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            if (1 < scroll) {
                $('body').addClass('scroll');
            } else {
                $('body').removeClass('scroll');
            }
        });
    }
});
